import React, { Component } from "react";
import NavBar from "../components/NavBar";
import BrandLogoSlider from "../components/BrandLogoSlider";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import SlimGallery from "./SlimGallery";
class SlimOs extends Component {
  render() {
    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div className="breadcrumb-area breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Serrure Électronique pour Hôtel OS SLIM</h1>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="projects">Produits</a>
                    </li>
                    <li>Details</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        {/*====================  project details page content ====================*/}
        <div className="page-wrapper section-space--inner--120">
          {/*Projects section start*/}
          <div className="project-section">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <SlimGallery />
                </div>

                <div className="col-lg-4 col-12 section-space--bottom--30">
                  <div className="project-information">
                    <h3>Information</h3>
                    <ul>
                      <li>
                        <strong>Ad Hoc:</strong>Serrure électronique qui
                        s'adapte aux coffres à mortaiser, même sur les portes en
                        aluminium à profil étroit.
                      </li>
                      <li>
                        <strong>Design:</strong>plaques SLIM à profil étroit et
                        lignes droites, avec une esthétique mince et
                        minimaliste. Finitions: inox, black edition, blanc,
                        doré, anti-bactérien
                      </li>
                      <li>
                        <strong>Audit:</strong>Ouvertures, tentatives
                        d'ouverture, sorties, etc. Audit synchronisé avec le PC
                        de la réception.
                      </li>
                      <li>
                        <strong>Bluetooth:</strong>Pour ouvertures de client,
                        mise en marche, gestion, et maintenance de la serrure
                        électronique.
                      </li>
                      <li>
                        <strong>On-Line:</strong>Oaouverture et gestion à
                        distance, en ajoutant des Gateways à l'installation.
                      </li>
                      <li>
                        <strong>Controle:</strong>Installer dans une chambre de
                        l'hôtel la serrure électronique OS SLIM, le coffre-fort
                        EXO et une Gateway permet de gérer et de contrôler à
                        distance les deux dispositifs.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-8 col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15">
                  <div className="project-details">
                    <h2>Serrure Électronique pour Hôtel OS SLIM</h2>
                    <h3>
                      Serrure électronique avec bluetooth et proximité MiFare
                    </h3>
                    <p>
                      La serrure électronique OS SLIM se distingue par son
                      design mince avec des plaques étroites qui donnent à la
                      porte et à la pièce un plus de style et d'élégance.
                    </p>
                    <p>
                      Son haut niveau technologique plus l'ouverture via
                      Bluetooth, carte de proximité MiFare et à distance, via
                      Gateway, font d'OS SLIM l'un des modèles à grande
                      projection dans le secteur hôtelier.
                    </p>
                    <h4>Equipements</h4>
                    <li>
                      Ouverture de la serrure électronique via Bluetooth et par
                      carte de proximité MiFare.
                    </li>
                    <li>
                      Option d’ouverture et gestion á distance grâce à
                      l’installation de Gateways.
                    </li>
                    <li>
                      Plaque avec poignée adaptable pour ouverture gauche ou
                      droite.
                    </li>
                    <li>
                      Couleurs laquées par PVD (Phase Vapor Deposition), qui
                      confère une plus grande résistance à l'abrasion et à la
                      corrosion.
                    </li>
                    <li>
                      Résistante aux environnements climatiques les plus
                      défavorables. Fabrication en acier inoxydable AISI 304 et
                      316.
                    </li>
                    <li>
                      Fonction « Ne pas déranger » avec indicateur visuel
                      extérieur pour éviter que des personnes non autorisées
                      n’entrent.
                    </li>
                    <li>
                      Cylindre mécanique invisible pour effectuer des ouvertures
                      en cas d’urgence et connecteur micro USB de type C, pour
                      l'alimentation en cas de piles déchargées, cachées et
                      protégées par un bouchon en caoutchouc.
                    </li>
                    <li>
                      Indicateurs visuels et acoustiques pour faciliter leur
                      utilisation par des personnes handicapées, ainsi que pour
                      des alertes de faible charge des piles pour le personnel,
                      un indicateur de chambre occupée, etc..
                    </li>
                    <li>
                      App unique pour l’administrateur, le personnel de l’hôtel
                      et les hôtes. UpKey offre différentes fonctionnalités
                      selon le profil de l’utilisateur enregistré.
                    </li>
                    <li>
                      L’hôte se connecte automatiquement sur l’App à travers un
                      lien généré par l’hôtel et qu’il reçoit par email.
                    </li>
                    <li>
                      Option d’App personnalisable et intégration de tiers.
                    </li>
                    <li>Elles fonctionnent avec 4 piles standard AA.</li>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Projects section end*/}
        </div>

        {/*====================  End of project details page content  ====================*/}

        {/* Brand logo */}
        <div className="top-bar-right-wrapper">
          <a
            href={`${process.env.PUBLIC_URL}/contact-us`}
            className="ht-btn ht-btn--default d-inline-block">
            OBTENIR UN DEVIS
          </a>
        </div>
        <BrandLogoSlider background="grey-bg" />

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default SlimOs;
