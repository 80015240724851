import React, { Component } from "react";
import NavBar from "../components/NavBar";
import BrandLogoSlider from "../components/BrandLogoSlider";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import ModalVideo from "react-modal-video";
class About extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div className="breadcrumb-area breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1> À PROPOS</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        <div className="page-wrapper section-space--inner--top--120">
          {/*Qui somme Nous*/}
          <div className="about-section section-space--inner--bottom--120">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-lg-6 col-12 mb-30">
                  <div className="about-image-two">
                    <img src="assets/img/logo/" alt="" />
                    <span className="video-popup">
                      <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="OrS-93U4AYQ"
                        onClose={() => this.setState({ isOpen: false })}
                      />
                    </span>
                  </div>
                </div>
                <div className="col-lg-6 col-12 mb-30">
                  <div className="about-content-two">
                    <h3>Bienvenue chez ACOS-TEC</h3>
                    <h1>Qui somme Nous ?</h1>
                    <h4>
                      ACOS-TEC est une société de droit algérien, spécialisée
                      dans la fourniture, la Réalisation et la mise en service
                      des projets de control d’accès, d’automatisme et de
                      control de procédé industriel.
                    </h4>
                    <h5>
                      ACOS-TEC se compose aujourd’hui d’ingénieurs expérimentés
                      dans l’étude, le Développement et la réalisation des
                      solutions basée sur des systèmes de control d’accès, pour
                      des applications de control de procédé simple ou
                      nécessitant une haute sécurité et disponibilité.
                    </h5>
                    <a
                      href="hotels"
                      className="ht-btn--default ht-btn--default--dark-hover section-space--top--20">
                      Nos services
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Notre mission et objectif*/}
          <div className="about-section section-space--inner--bottom--120">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-lg-6 col-12 mb-30">
                  <div className="about-content-two">
                    <h3>Notre mission et objectif</h3>
                    <h4>
                      Notre principale mission, est de mettre à la disposition
                      de nos clients notre savoir-faire et compétences afin
                      d’offrir nos solutions et services adaptés à leur besoin
                      et assurer une meilleure compétitivité, utilisant des
                      produits de haute qualité et fiabilité.
                    </h4>
                    <h4>
                      Nous offrons une gamme de services et des solutions
                      variées dans les domaines de:
                    </h4>
                    <li>Control D’accès</li>
                    <li>Identification électronique et lecture biométriques</li>
                    <li>Automatisme</li>
                    <li>L’Electricité et l’Instrumentation</li>
                    <li>Informatique et Standard téléphonique</li>
                    <li>Vidéo surveillances</li>
                    <li>Détection incendie, désenfumage</li>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Domaines d’Application*/}
          <div className="about-section section-space--inner--bottom--120">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-lg-6 col-12 mb-30">
                  <div className="about-image-two">
                    <img src="assets/img/logo/" alt="" />
                    <span className="video-popup">
                      <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="OrS-93U4AYQ"
                        onClose={() => this.setState({ isOpen: false })}
                      />
                    </span>
                  </div>
                </div>
                <div className="col-lg-6 col-12 mb-30">
                  <div className="about-content-two">
                    <h3>Domaines d’Application</h3>
                    <h4>
                      Notre savoir-faire dans le contrôle d’accès, nous permet
                      aujourd’hui à travers notre société d’opérer dans
                      différents domaines à savoir :
                    </h4>
                    <li>Hôtelleries</li>
                    <li>Administrations</li>
                    <li>Résidences</li>
                    <li>Zones industriels</li>
                    <li>Bases de vie</li>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*Fourniture*/}
          <div className="about-section section-space--inner--bottom--120">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-lg-6 col-12 mb-30">
                  <div className="about-content-two">
                    <h3>Fourniture</h3>
                    <h4>Instrumentation</h4>
                    <h4>
                      Fourniture de solution complète de control d’accès pour
                      les Hôtels à savoir:
                    </h4>
                    <li>
                      Serrure électronique avec le system de gestion de control
                    </li>
                    <li>
                      Fourniture des lecteurs de document biométriques (carte
                      nationale et Passeport)
                    </li>
                    <li>
                      Fournitures des réseaux informatique, switches, routers,
                      points d’accès, firewall..
                    </li>
                    <li>Fournitures des réseaux et standard téléphoniques.</li>
                    <li>Fournitures des réseaux de vidéo surveillance.</li>
                    <li>Matériel informatique</li>
                    <h4>
                      Instrumentation pour la détection et extinction Feu & Gaz
                      à savoir
                    </h4>
                    <li>Détecteur de fumée</li>
                    <li>Détecteur de Température</li>
                    <li>Sirène</li>
                    <li>Brise-Glace</li>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*Services*/}
          <div className="about-section section-space--inner--bottom--120">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-lg-6 col-12 mb-30">
                  <div className="about-image-two">
                    <img src="assets/img/logo/" alt="" />
                    <span className="video-popup">
                      <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="OrS-93U4AYQ"
                        onClose={() => this.setState({ isOpen: false })}
                      />
                    </span>
                  </div>
                </div>
                <div className="col-lg-6 col-12 mb-30">
                  <div className="about-content-two">
                    <h3>Services</h3>

                    <h4>Nous couvrons les différents services suivants:</h4>
                    <li>Service et support des systèmes de control d’accès</li>
                    <li>
                      Service et support en réseaux Informatique, Téléphonique
                      et vidéo surveillance
                    </li>
                    <li>Support Informatique et maintenance.</li>
                    <li>Service et Support d’Ingénierie</li>
                    <li>
                      Visite de Reconnaissance chez nos client "Site Survey"
                    </li>
                    <li>Définition des besoins Client</li>
                    <li>Formation du personnel</li>
                    <li>Contrat de maintenance et Service Après-Vente</li>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Fun fact */}

          {/* Team member */}

          {/* Testimonial Slider */}

          {/* Brand logo */}

          <BrandLogoSlider background="grey-bg" />
        </div>

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default About;
