import React, { Component } from "react";
import NavBar from "../components/NavBar";
import BrandLogoSlider from "../components/BrandLogoSlider";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import PhotoGallery from "./PhotoGallery";
class Projects extends Component {
  render() {
    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div className="breadcrumb-area breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Serrure Électronique d’Hôtel Connectée EVO</h1>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="projects">Produits</a>
                    </li>
                    <li>Details</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        {/*====================  project details page content ====================*/}
        <div className="page-wrapper section-space--inner--120">
          {/*Projects section start*/}
          <div className="project-section">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <PhotoGallery />
                </div>

                <div className="col-lg-4 col-12 section-space--bottom--30">
                  <div className="project-information">
                    <h3>Information</h3>
                    <ul>
                      <li>
                        <strong>Minimaliste:</strong> De par son design innovant
                        et avant-garde.
                      </li>
                      <li>
                        <strong>Design:</strong>Conçu pour des nouvelles portes,
                        sa configuration permet de choisir librement la poignée,
                        avec mécanismes, électronique et batteries placés à
                        l’intérieur de la porte, lecteur et entrées de clé
                        personnalisables.
                      </li>
                      <li>
                        <strong>Audit:</strong>Ouvertures,tentatives
                        d'ouverture,sorties,etc.Audit synchronisé avec le PC de
                        la réception.
                      </li>
                      <li>
                        <strong>Bluetooth:</strong>Pour ouvertures de client,
                        mise en marche, gestion, et maintenance de la serrure
                        électronique.
                      </li>
                      <li>
                        <strong>On-Line:</strong>Ouverture et gestion à
                        distance, en ajoutant des passerelles à l'installation.
                      </li>
                      <li>
                        <strong>Controle:</strong>Installer dans une chambre de
                        l'hôtel la serrure électronique EVO, le coffre-fort EXO
                        et une passerelle, on permet de gèrer et de controller à
                        distance les deux dispositifs.
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-8 col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15">
                  <div className="project-details">
                    <h2>Serrure Électronique d’Hôtel Connectée EVO</h2>
                    <h3>Serrure Électronique Bluetooth et Proximité</h3>
                    <p>
                      Le design, la technologie et l’innovation convergent dans
                      EVO pour fournir une serrure électronique d’hôtel de haute
                      performance.
                    </p>
                    <p>
                      La serrure électronique EVO intègre une innovante
                      technologie de contrôle d’accès, développée par OMNITEC,
                      et apporte aux hôtels un plus en matière de sécurité et de
                      contrôle, encore jamais.
                    </p>
                    <h4>Equipements</h4>
                    <li>Ouverture via Bluetooth et par proximité MiFare.</li>
                    <li>
                      Option d’ouverture et gestion on-line grâce à
                      l’installation de passerelles.
                    </li>
                    <li>
                      Électronique intégrée dans la porte, sans antennes ni
                      connecteurs externes exposés à une manipulation
                      incorrecte.
                    </li>
                    <li>
                      Option de verrouillage du loquet et d’activation du mode
                      confidentialité par bouton ou rotation inverse de la
                      poignée:
                    </li>
                    <ol>
                      Mode Hôtel : on peut seulement fermer de l’intérieur de la
                      chambre, avec le bouton ou par rotation inverse de la
                      poignée.
                    </ol>
                    <ol>
                      Mode A.R.B. (Appartement-Résidence-Bureau) : il permet de
                      verrouiller le loquet et d’activer le mode confidentialité
                      des deux côtés de la porte par rotation inverse de la
                      poignée.
                    </ol>
                    <li>
                      Résistante aux environnements climatiques les plus
                      défavorables. Fabrication en acier inoxydable AISI 304.
                    </li>
                    <li>Certification IP 56</li>
                    <li>
                      Indicateurs visuels et acoustiques pour faciliter leur
                      utilisation par des personnes handicapées, ainsi que pour
                      des alertes de faible charge des piles pour le personnel,
                      un indicateur de chambre occupée, etc.
                    </li>
                    <li>
                      Pêne dormant silencieux, dispositif anti-carte et fonction
                      anti-panique.
                    </li>
                    <li>
                      Une unique serrure a mortaiser pour tous les portes, quel
                      que soit leur sens d’ouverture.
                    </li>
                    <li>
                      Cylindre mécanique invisible pour effectuer des ouvertures
                      en cas d’urgence.
                    </li>
                    <li>
                      App unique pour l’administrateur, le personnel de l’hôtel
                      et les hôtes. UpKey offre différentes fonctionnalités
                      selon le profil de l’utilisateur enregistré.
                    </li>
                    <li>
                      L’hôte se connecte automatiquement sur l’App à travers un
                      lien généré par l’hôtel et qu’il reçoit par email.
                    </li>
                    <li>
                      Option d’App personnalisable et intégration de tiers.
                    </li>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Projects section end*/}
        </div>

        {/*====================  End of project details page content  ====================*/}

        {/* Brand logo */}
        <div className="top-bar-right-wrapper">
          <a
            href={`${process.env.PUBLIC_URL}/contact-us`}
            className="ht-btn ht-btn--default d-inline-block">
            OBTENIR UN DEVIS
          </a>
        </div>
        <BrandLogoSlider background="grey-bg" />

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default Projects;
