import React, { Component } from "react";
import NavBar from "../components/NavBar";
import BrandLogoSlider from "../components/BrandLogoSlider";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import KeySafeGallery from "./KeySafeGallery";
class KeySafe extends Component {
  render() {
    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div className="breadcrumb-area breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Coffret Garde-Clés de Sécurité KeySafe </h1>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="projects">Produits</a>
                    </li>
                    <li>Details</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        {/*====================  project details page content ====================*/}
        <div className="page-wrapper section-space--inner--120">
          {/*Projects section start*/}
          <div className="project-section">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <KeySafeGallery />
                </div>

                <div className="col-lg-8 col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15">
                  <div className="project-details">
                    <h2>Coffret Garde-Clés de Sécurité KeySafe</h2>
                    <h3>
                      Remise des clés avec un code PIN ou une clé Bluetooth
                    </h3>
                    <p>
                      Keysafe est un coffret garde-clés de sécurité, géré et
                      contrôlé grâce aux APP et/ou le logiciel d’Omnitec.
                    </p>
                    <p>
                      Pensé pour des appartements touristiques, des petits
                      hôtels sans réception 24h sur 24, des appart-hôtels et des
                      agences immobilières, la fonctionnalité de Keysafe
                      simplifie la gestion pour le propriétaire et permet
                      d’améliorer l’expérience de l’hôte.
                    </p>
                    <p>
                      Son système de gestion à distance évite au propriétaire et
                      à l’hôte d’avoir à se rencontrer pour la remise des clés
                      traditionnelles ou des cartes de proximité.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Projects section end*/}
        </div>

        {/*====================  End of project details page content  ====================*/}

        {/* Brand logo */}
        <div className="top-bar-right-wrapper">
          <a
            href={`${process.env.PUBLIC_URL}/contact-us`}
            className="ht-btn ht-btn--default d-inline-block">
            OBTENIR UN DEVIS
          </a>
        </div>
        <BrandLogoSlider background="grey-bg" />

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default KeySafe;
