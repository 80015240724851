import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import Home from "./Home";
import About from "./pages/About";
import Hotels from "./service/Hotels";
import Projects from "./service/Projects";
import LecteurBiometrique from "./service/LecteurBiometrique";
import ProjectDetails from "./service/ProjectDetails";
import SlimOs from "./service/SlimOs";
import SlimCode from "./service/SlimCode";
import Gaudi from "./service/Gaudi";
import FitIn from "./service/FitIn";
import Flat from "./service/Flat";
import Gateway from "./service/Gateway";
import KeySafe from "./service/KeySafe";
import UhfRfid from "./service/UhfRfid";
import Contact from "./pages/Contact";
import PageNotFound from "./pages/404";
import NoMAtch from "./pages/404";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <Switch>
          <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/hotels`}
            component={Hotels}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Projects`}
            component={Projects}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/project-details`}
            component={ProjectDetails}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/slimos`}
            component={SlimOs}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/slimcode`}
            component={SlimCode}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Gaudi`}
            component={Gaudi}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Gateway`}
            component={Gateway}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/fitin`}
            component={FitIn}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/flat`}
            component={Flat}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/keysafe`}
            component={KeySafe}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/LecteurBiometrique`}
            component={LecteurBiometrique}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/UhfRfid`}
            component={UhfRfid}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/About`}
            component={About}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Contact`}
            component={Contact}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/404`}
            component={PageNotFound}
          />
          <Route component={NoMAtch} />
        </Switch>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.register();
