import React, { Component } from "react";
import NavBar from "../components/NavBar";
import BrandLogoSlider from "../components/BrandLogoSlider";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import FlatGallery from "./FlatGallery";
class Flat extends Component {
  render() {
    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div className="breadcrumb-area breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Serrure Électronique pour Hôtel FLAT 2</h1>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="projects">Produits</a>
                    </li>
                    <li>Details</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        {/*====================  project details page content ====================*/}
        <div className="page-wrapper section-space--inner--120">
          {/*Projects section start*/}
          <div className="project-section">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <FlatGallery />
                </div>

                <div className="col-lg-4 col-12 section-space--bottom--30">
                  <div className="project-information">
                    <h3>Information</h3>
                    <ul>
                      <li>
                        <strong>Ouverture:</strong>Via Bluetooth, carte de
                        proximité, code et en ligne par Gateway.
                      </li>
                      <li>
                        <strong>Privacité:</strong>Fonction pour éviter que des
                        personnes non autorisées n’entrent.
                      </li>
                      <li>
                        <strong>Audit:</strong>Ouvertures, tentatives
                        d'ouverture, sorties, etc. Audit synchronisé avec le PC
                        de la réception.
                      </li>
                      <li>
                        <strong>Bluetooth:</strong>Pour ouvertures de client,
                        mise en marche, gestion, et maintenance de la serrure
                        électronique.
                      </li>
                      <li>
                        <strong>Gestion:</strong>Avec le logiciel OS ACCESS ou
                        avec l'application mobile RENT & PASS.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-8 col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15">
                  <div className="project-details">
                    <h2>Serrure Électronique pour Hôtel FLAT 2</h2>
                    <h3>
                      Ouverture via Bluetooth, Code Numérique et Carte de
                      Proximité Mifare
                    </h3>
                    <p>
                      La serrure électronique FLAT 2 a été conçue pour s'adapter
                      aux coffres à mortaiser existants et moderniser les
                      portes, en les équipant d'un système de contrôle d'accès
                      pratique et sûr.
                    </p>
                    <p>
                      Son système de gestion à distance fait de la serrure
                      électronique FLAT 2 un modèle idéal pour les petits
                      hôtels, les hôtels sans réception 24h / 24 et les gîtes
                      ruraux ou les locations de vacancees décentralisés.
                    </p>
                    <p>
                      Une fois la réservation est confirmée, l'hôtel envoie un
                      code et / ou une clé Bluetooth à l'hôte, avec une validité
                      ajustée à la réservation.
                    </p>

                    <h4>Equipements</h4>
                    <li>
                      Ouverture via Bluetooth, par carte de proximité MiFare et
                      code numérique.
                    </li>
                    <li>
                      Option d’ouverture et gestion en ligne grâce à
                      l’installation de Gateways.
                    </li>
                    <li>
                      Clavier rétro-éclairé pour faciliter son utilisation dans
                      les couloirs et des pièces à faible éclairage.
                    </li>
                    <li>
                      Fonction anti-panique pour garantir les sorties en cas
                      d’urgence.
                    </li>
                    <li>
                      Deux systèmes de gestion différenciés pour les hôtels de
                      toutes tailles et pour les locations de vacances:
                    </li>
                    <ol>
                      Logiciel OS ACCESS: logiciel pour la gestion depuis le PC
                      de la réception. L’application UpKey complète le logiciel
                      pour l'installation, la maintenance et pour les ouvertures
                      Bluetooth des travailleurs et des hôtes.
                    </ol>
                    <ol>
                      APP RENT&PASS: application mobile pour l'installation, la
                      gestion, la maintenance, et l'ouverture des employés et
                      des hôtes. Conçu pour les hôtels sans réception 24h / 24
                      et pour des locations de vacances.
                    </ol>

                    <li>
                      Résistante aux environnements climatiques les plus
                      défavorables. Fabrication en acier inoxydable AISI 304.
                    </li>
                    <li>
                      Cylindre mécanique invisible pour effectuer des ouvertures
                      en cas d’urgence.
                    </li>
                    <li>
                      Connecteur micro USB pour l'alimentation, avec un
                      powerbank, en cas de batterie déchargée.
                    </li>
                    <li>
                      Elle fonctionne avec 4 piles standard AA d’une durée
                      allant jusqu’à 200 000 ouvertures.
                    </li>
                    <li>Elle s'adapte aux coffres à mortaiser DIN.</li>
                    <li>Dimensions: 65 x 150 mm</li>
                    <li> Épaisseur de porte admise: de 38 à 52 mm</li>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Projects section end*/}
        </div>

        {/*====================  End of project details page content  ====================*/}

        {/* Brand logo */}
        <div className="top-bar-right-wrapper">
          <a
            href={`${process.env.PUBLIC_URL}/contact-us`}
            className="ht-btn ht-btn--default d-inline-block">
            OBTENIR UN DEVIS
          </a>
        </div>
        <BrandLogoSlider background="grey-bg" />

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default Flat;
