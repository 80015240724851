import React, { Component } from "react";

class SidebarUhf extends Component {
  render() {
    let data = [
      {
        sidebarListLink: "",
        sidebarListIcon: "",
        sidebarListText: "Product Code : RRUHFR03",
      },
      {
        sidebarListLink: "",
        sidebarListIcon: "",
        sidebarListText: "Material : ABS Plastic",
      },
      {
        sidebarListLink: "",
        sidebarListIcon: "",
        sidebarListText: "Colour : White",
      },
      {
        sidebarListLink: "",
        sidebarListIcon: "",
        sidebarListText: "Memory : 128 mb",
      },
      {
        sidebarListLink: "",
        sidebarListIcon: "",
        sidebarListText: "Thickness : 60mm",
      },
      {
        sidebarListLink: "",
        sidebarListIcon: "",
        sidebarListText: "Size (in mm) : 245mm X 245mm X 60mm",
      },
      {
        sidebarListLink: "",
        sidebarListIcon: "",
        sidebarListText: "Operating Frequency : 865-867 MHz",
      },
      {
        sidebarListLink: "",
        sidebarListIcon: "",
        sidebarListText: "Standard / Protocols : ISO 18000-6C: EPC Class1 Gen2",
      },
      {
        sidebarListLink: "",
        sidebarListIcon: "",
        sidebarListText: "Temparature : -12°C to +50°C",
      },
      {
        sidebarListLink: "",
        sidebarListIcon: "",
        sidebarListText:
          "Communication Interface : Ethernet / Wifi (opional) / Bluetooth (optional)",
      },
    ];

    let Datalist = data.map((val, i) => {
      return (
        <li key={i}>
          <a href={val.sidebarListLink}>
            <i className={val.sidebarListIcon} />
            {val.sidebarListText}
          </a>
        </li>
      );
    });

    return (
      <div>
        <div className="sidebar-wrapper">
          <div className="sidebar">
            <h3 className="sidebar-title">Information technique </h3>
            <ul className="sidebar-list">{Datalist}</ul>
          </div>
        </div>
      </div>
    );
  }
}

export default SidebarUhf;
