import React, { Component } from "react";
class NavBar extends Component {
  render() {
    return (
      <div>
        {/*====================  header area ====================*/}
        <div className="header-area header-sticky header-sticky--default">
          <div className="header-area__desktop header-area__desktop--default">
            {/*=======  header top bar  =======*/}

            {/*=======  End of header top bar  =======*/}
            {/*=======  header info area  =======*/}
            <div className="header-info-area">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-12">
                    <div className="header-info-wrapper align-items-center">
                      {/* logo */}
                      <div className="logo">
                        <a href={`${process.env.PUBLIC_URL}/`}>
                          <img
                            src="assets/img/logo/logo.png"
                            className="img-fluid"
                            alt="Logo"
                          />
                        </a>
                      </div>
                      {/* header contact info */}

                      {/* mobile menu */}
                      <div
                        className="mobile-navigation-icon"
                        id="mobile-menu-trigger">
                        <i />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*=======  End of header info area =======*/}
            {/*=======  header navigation area  =======*/}
            <div className="header-navigation-area default-bg">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    {/* header navigation */}
                    <div className="header-navigation header-navigation--header-default position-relative">
                      <div className="header-navigation__nav position-static">
                        <nav>
                          <ul>
                            <li>
                              <a href={`${process.env.PUBLIC_URL}/`}>ACCUEIL</a>
                            </li>

                            <li className="has-children has-children--multilevel-submenu">
                              <a href={`${process.env.PUBLIC_URL}/`}>SERVICE</a>
                              <ul className="submenu">
                                <li>
                                  <a href={`${process.env.PUBLIC_URL}/hotels`}>
                                    Solution Pour Les Hotels
                                  </a>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href={`${process.env.PUBLIC_URL}/About`}>
                                À PROPOS
                              </a>
                            </li>
                            <li>
                              <a href={`${process.env.PUBLIC_URL}/Contact`}>
                                CONTACT
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*=======  End of header navigation area =======*/}
          </div>
        </div>
        {/*====================  End of header area  ====================*/}
      </div>
    );
  }
}

export default NavBar;
