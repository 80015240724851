import React, { Component } from "react";
import NavBar from "../components/NavBar";
import Sidebarlb from "./components/Sidebarlb";
import BiometriqueGallery from "./components/BiometriqueGallery";
import BrandLogoSlider from "../components/BrandLogoSlider";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";

class LecteurBiometrique extends Component {
  render() {
    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div className="breadcrumb-area breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Lecteur Biometrique</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        <div className="page-wrapper section-space--inner--120">
          {/*Service section start*/}
          <div className="service-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-12 order-1 order-lg-2">
                  <div className="service-details">
                    {/* service gallery */}
                    <BiometriqueGallery />
                    <div className="content section-space--top--30">
                      <div className="row">
                        <div className="col-12">
                          <h2>Caractéristiques</h2>
                          <p>
                            4-in-1: ISO14443 contactless reader, 2x ISO 7816
                            contact reader, ISO 7816 SAM reader, OCR scanner
                          </p>
                        </div>
                        <div className="col-12">
                          <h2>Avantages</h2>
                          <p>
                            Read any MRZ & contactless chip without user action.
                            Cost efficient compared to complete datapage
                            scanner. Read both MRZ characters and contactless
                            chip data without movement.
                          </p>

                          <h2>Marchés</h2>
                          <p>
                            Tout endroit où une analyse complète de la page de
                            données n'est pas nécessaire et la facilité
                            d'utilisation est requise : pièce d'identité de la
                            police, Enregistrement à l'aéroport, Bornes
                            libre-service, Frontière contrôle, hôtels, banques,
                            sociétés de location de voitures.
                          </p>
                          <h2>Clients</h2>
                          <p>
                            Entreprises publiques et privées nécessitant une
                            vérification d'identité.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-12 order-2 order-lg-1">
                  <Sidebarlb />
                </div>
              </div>
            </div>
          </div>
          {/*Service section end*/}
        </div>

        {/* Brand logo */}
        <div className="top-bar-right-wrapper">
          <a
            href={`${process.env.PUBLIC_URL}/contact-us`}
            className="ht-btn ht-btn--default d-inline-block">
            OBTENIR UN DEVIS
          </a>
        </div>
        <BrandLogoSlider background="grey-bg" />

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default LecteurBiometrique;
