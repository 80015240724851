import React, { Component } from "react";
import NavBar from "../components/NavBar";
import BrandLogoSlider from "../components/BrandLogoSlider";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import GaudiGallery from "./GaudiGallery";
class Gaudi extends Component {
  render() {
    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div className="breadcrumb-area breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Serrure Électronique pour Hôtel GAUDI </h1>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="projects">Produits</a>
                    </li>
                    <li>Details</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        {/*====================  project details page content ====================*/}
        <div className="page-wrapper section-space--inner--120">
          {/*Projects section start*/}
          <div className="project-section">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <GaudiGallery />
                </div>

                <div className="col-lg-4 col-12 section-space--bottom--30">
                  <div className="project-information">
                    <h3>Information</h3>
                    <ul>
                      <li>
                        <strong>Ad Hoc:</strong>Serrure électronique qui
                        s'adapte aux coffres à mortaiser, même sur les portes en
                        aluminium à profil étroit.
                      </li>
                      <li>
                        <strong>Design:</strong>plaques slim à profil étroit et
                        lignes droites
                      </li>
                      <li>
                        <strong>Audit:</strong>Ouvertures, tentatives
                        d'ouverture, sorties, etc. Audit synchronisé avec le PC
                        de la réception.
                      </li>
                      <li>
                        <strong>Bluetooth:</strong>Pour ouvertures de client,
                        mise en marche, gestion, et maintenance de la serrure
                        électronique.
                      </li>
                      <li>
                        <strong>On-Line:</strong>Aouverture et gestion à
                        distance, en ajoutant des Gateways à l'installation.
                      </li>
                      <li>
                        <strong>Controle:</strong>Installer dans une chambre de
                        l'hôtel la serrure électronique OS SLIM, le coffre-fort
                        EXO et une Gateway permet de gérer et de contrôler à
                        distance les deux dispositifs. Compatible avec la
                        fonction de sécurité GUEST-PRESENCE du coffre-fort EXO.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-8 col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15">
                  <div className="project-details">
                    <h2>Serrure Électronique pour Hôtel GAUDI </h2>
                    <h3>Serrure Électronique de Proximité pour Hôtel</h3>
                    <p>Sobre, robuste et facile à installer.</p>
                    <p>
                      Spécialement conçue pour hôtels, bureaux et résidences,
                      GAUDI intègre toutes les prestations de sécurité en un
                      seul système fiable et efficace.
                    </p>
                    <p>
                      La serrure électronique de proximité ayant le meilleur
                      rapport qualité-prix du marché.
                    </p>
                    <p>Ouverture Bluetooth via APP en option.</p>
                    <h4>Equipements</h4>
                    <li>
                      Système d’ouverture par carte de proximité MIFARE 13,56
                      Mhz, et Bluetooth en option.
                    </li>
                    <li>
                      Fonctionnement garanti dans les environnements climatiques
                      les plus défavorables grâce à leur fabrication en acier
                      inoxydable AISI 304 et à leur électronique tropicalisée.
                    </li>
                    <li>
                      Serrure électronique avec d’indicateurs visuels et
                      acoustiques pour faciliter leur utilisation par des
                      personnes handicapées, ainsi que pour incorporer des
                      fonctions supplémentaires comme des alertes de faible
                      charge des piles pour le personnel, un indicateur de
                      chambre occupée, etc.
                    </li>
                    <li>
                      Fonction « Ne pas déranger » électromécanique avec
                      indicateur visuel extérieur pour éviter que des personnes
                      non autorisées n’entrent.
                    </li>
                    <li>
                      Elles incorporent une mémoire EPROM non volatile qui, même
                      en cas de changement de piles, permet de conserver les 400
                      dernières ouvertures réalisées, avec les données de
                      l’usager, la date et l’heure.
                    </li>
                    <li>
                      Équipées d’horloge en temps réel pour faciliter la gestion
                      de tous les accès effectués par le personnel et les hôtes.
                    </li>
                    <li>
                      Les serrures électroniques Gaudi disposent d’un cylindre
                      mécanique invisible pour effectuer des ouvertures en cas
                      d’urgence.
                    </li>
                    <li>
                      Elles fonctionnent avec 4 piles standard AA d’une durée
                      allant jusqu’à 20.000 ouvertures.
                    </li>
                    <li>
                      Configuration et audit des serrures par télécommande sans
                      fil.
                    </li>
                    <li>
                      Serrure électronique Free Turn avec mécanisme EURO comme
                      standard. Mécanisme ANSI avec poignée fixe.
                    </li>
                    <li>
                      Avec l'installation de Gateways, tous les appareils
                      équipés de Bluetooth deviennent des appareils en ligne,
                      permettant la gestion à distance depuis le PC de réception
                      ou depuis un Smartphone.
                    </li>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Projects section end*/}
        </div>

        {/*====================  End of project details page content  ====================*/}

        {/* Brand logo */}
        <div className="top-bar-right-wrapper">
          <a
            href={`${process.env.PUBLIC_URL}/contact-us`}
            className="ht-btn ht-btn--default d-inline-block">
            OBTENIR UN DEVIS
          </a>
        </div>
        <BrandLogoSlider background="grey-bg" />

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default Gaudi;
