import React, { Component } from "react";
import NavBar from "../components/NavBar";
import BrandLogoSlider from "../components/BrandLogoSlider";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
class Projects extends Component {
  render() {
    let data = [
      {
        pageLink: "project-details",
        img: "Cerradura-Electronica-Hotel-EVO.jpg",
        projectTitle: "Serrure Électronique d’Hôtel Connectée EVO",
        projectSubtitle: "",
      },
      {
        pageLink: "slimos",
        img: "Cerradura-Electronica-Hotel-SLIM.jpg",
        projectTitle: "Serrure Électronique pour Hôtel OS SLIM ",
        projectSubtitle: "",
      },
      {
        pageLink: "slimcode",
        img: "Cerradura-Electronica-Hotel-CODE-SLIM.jpg",
        projectTitle: "Serrure Électronique pour Hôtel OS SLIM CODE",
        projectSubtitle: "",
      },
      {
        pageLink: "gaudi",
        img: "Cerradura-Electronica-Gaudi2.jpg",
        projectTitle: "Serrure Électronique pour Hôtel GAUDI",
        projectSubtitle: "",
      },
      {
        pageLink: "fitin",
        img: "Cerradura-Electronica-FitIn2.jpg",
        projectTitle: "Serrure Électronique pour Hôtel Fit-In",
        projectSubtitle: "",
      },
      {
        pageLink: "flat",
        img: "Cerradura-Electronica-Hotel-Teclado-Flat2.jpg",
        projectTitle: "Serrure Électronique pour Hôtel FLAT 2",
        projectSubtitle: "",
      },
      {
        pageLink: "gateway",
        img: "Gateway-Vivienda-Vacacional.jpg",
        projectTitle: "Passerrelle On-line Gateway",
        projectSubtitle: "",
      },
      {
        pageLink: "keysafe",
        img: "KeySafe-Keybox-Guarda-Llaves.jpg",
        projectTitle: "Coffret Garde-Clés de Sécurité Électronique KeySafe",
        projectSubtitle: "",
      },
    ];

    let Datalist = data.map((val, i) => {
      return (
        <div
          className="col-lg-4 col-sm-6 col-12 section-space--bottom--30"
          key={i}>
          <div className="service-grid-item service-grid-item--style2">
            <div className="service-grid-item__image">
              <div className="service-grid-item__image-wrapper">
                <a href={`${process.env.PUBLIC_URL}/${val.pageLink}`}>
                  <img
                    src={`assets/img/projects/${val.img}`}
                    className="img-fluid"
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div className="service-grid-item__content">
              <h3 className="title">
                <a href={`${process.env.PUBLIC_URL}/${val.pageLink}`}>
                  {val.projectTitle}
                </a>
              </h3>
              <p className="subtitle">{val.projectSubtitle}</p>
              <a
                href={`${process.env.PUBLIC_URL}/${val.pageLink}`}
                className="see-more-link">
                VOIR PLUS
              </a>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div className="breadcrumb-area breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Produits</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        {/*====================  project page content ====================*/}
        <div className="page-wrapper section-space--inner--120">
          {/*Projects section start*/}
          <div className="project-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="project-item-wrapper">
                    <div className="row">{Datalist}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Projects section end*/}
        </div>

        {/*====================  End of project page content  ====================*/}

        {/* Brand logo */}
        <BrandLogoSlider background="grey-bg" />

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default Projects;
