import React, { Component } from "react";
import NavBar from "../components/NavBar";
import BrandLogoSlider from "../components/BrandLogoSlider";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import GatewayGallery from "./GatewayGallery";
class Gateway extends Component {
  render() {
    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div className="breadcrumb-area breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Passerrelle On-line Gateway </h1>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="projects">Produits</a>
                    </li>
                    <li>Details</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        {/*====================  project details page content ====================*/}
        <div className="page-wrapper section-space--inner--120">
          {/*Projects section start*/}
          <div className="project-section">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <GatewayGallery />
                </div>

                <div className="col-lg-4 col-12 section-space--bottom--30">
                  <div className="project-information">
                    <h3>Fonctions on-line</h3>
                    <ul>
                      <li>Audit On-Line</li>
                      <li>
                        Ouverture à distance de la réception ou du smartphone
                        administrateur
                      </li>
                      <li>Contrôle du niveau de batteries</li>
                      <li>Gestion des identifiants d’accès</li>
                      <li>Mise à jour Date/Heure</li>
                      <li>Alertes d'ouverture</li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-8 col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15">
                  <div className="project-details">
                    <h2>Passerrelle On-line Gateway </h2>
                    <h3>Connectivité pour gestion et ouvertures à distance</h3>
                    <p>
                      La passerelle OMNITEC transforme les serrures
                      électroniques Bluetooth et les contrôles d’accès Bluetooth
                      en dispositifs on-line.
                    </p>
                    <p>
                      À travers une connexion on-line via Wifi, les serrures et
                      contrôles d’accès transmettent les événements enregistrés
                      dans le nuage, à travers la passerelle, ce qui permet de
                      gérer les dispositifs à distance.
                    </p>

                    <h4>Equipements</h4>
                    <li>Portée Bluetooth: jusqu'à 10m</li>
                    <li>Réseau: WiFi 2.4G</li>
                    <li>EEE standard: 802.11 b/g/n</li>
                    <li>Connexion: USB Type C</li>
                    <li>Alimentation: DC 5V/500mA</li>
                    <li>Mesures: 70 x 104 x 18 mm</li>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Projects section end*/}
        </div>

        {/*====================  End of project details page content  ====================*/}

        {/* Brand logo */}
        <div className="top-bar-right-wrapper">
          <a
            href={`${process.env.PUBLIC_URL}/contact-us`}
            className="ht-btn ht-btn--default d-inline-block">
            OBTENIR UN DEVIS
          </a>
        </div>
        <BrandLogoSlider background="grey-bg" />

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default Gateway;
