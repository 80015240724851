import React, { Component } from "react";

class Sidebarlb extends Component {
  render() {
    let data = [
      {
        sidebarListLink: "",
        sidebarListIcon: "",
        sidebarListText: "Dimensions:140 x 90 x 115 mm for ID BOX One 151",
      },
      {
        sidebarListLink: "",
        sidebarListIcon: "",
        sidebarListText:
          "Temperature: In operation: 0°C to +50°C Storage: -40°C to +80°C",
      },
      {
        sidebarListLink: "",
        sidebarListIcon: "",
        sidebarListText: "Communication : USB 2.0 full speed (12 Mbps",
      },
      {
        sidebarListLink: "",
        sidebarListIcon: "",
        sidebarListText:
          "Host operating systems : Windows, MacOS, Linux, Android",
      },
    ];

    let Datalist = data.map((val, i) => {
      return (
        <li key={i}>
          <a href={val.sidebarListLink}>
            <i className={val.sidebarListIcon} />
            {val.sidebarListText}
          </a>
        </li>
      );
    });

    return (
      <div>
        <div className="sidebar-wrapper">
          <div className="sidebar">
            <h3 className="sidebar-title">Information technique </h3>
            <ul className="sidebar-list">{Datalist}</ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Sidebarlb;
