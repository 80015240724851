import React, { useState } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import * as emailjs from "emailjs-com";

const Contact = () => {
  const initialFormState = {
    name: "",
    email: "",
    message: "",
    objet: "",
  };

  const [contactData, setContactData] = useState({ ...initialFormState });

  const handleChange = ({ target }) => {
    setContactData({
      ...contactData,
      [target.name]: target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_i7lp475",
        "template_qidp6ef",
        e.target,
        "2F70netQBEj5Hg0rK"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    //reset the form after submission
    setContactData({ ...initialFormState });
  };
  return (
    <div>
      {/* Navigation bar */}
      <NavBar />

      {/* breadcrumb */}
      {/*====================  breadcrumb area ====================*/}
      <div className="breadcrumb-area breadcrumb-bg">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="page-banner text-center">
                <h1>Contact</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of breadcrumb area  ====================*/}

      {/*====================  content page content ====================*/}
      <div className="page-wrapper section-space--inner--120">
        {/*Contact section start*/}
        <div className="conact-section">
          <div className="container">
            <div className="row section-space--bottom--50">
              <div className="col">
                <div className="contact-map">
                  <iframe
                    title="google-map"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6394.595913061593!2d2.9479817!3d36.7394186!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x128fafe43fe194f3%3A0x5680d2c66bfe1a46!2sCoop%C3%A9rative%20El-Yasmine%2C%20Ouled%20Fayet!5e0!3m2!1sfr!2sdz!4v1690812159633!5m2!1sfr!2sdz"
                    allowFullScreen></iframe>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-12">
                <div className="contact-information">
                  <h3>Contact Information</h3>
                  <ul>
                    <li>
                      <span className="icon">
                        <i className="ion-android-map" />
                      </span>
                      <span className="text">
                        <span>
                          Lot° 339 El Yasmine, Div 02, Ouled Fayet, Alger
                          Algérie
                        </span>
                      </span>
                    </li>
                    <li>
                      <span className="icon">
                        <i className="ion-ios-telephone-outline" />
                      </span>
                      <span className="text">
                        <a href="tel:1234567890">(213) 550 01 95 08</a>
                      </span>
                    </li>
                    <li>
                      <span className="icon">
                        <i className="ion-ios-email-outline" />
                      </span>
                      <span className="text">
                        <a href="mailto:info@example.com">
                          contact@acos-dz.com
                        </a>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-8 col-12">
                <div className="contact-form">
                  <h3>Laissez votre message</h3>
                  <form onSubmit={handleSubmit}>
                    <div className="row row-10">
                      <div className="col-md-6 col-12 section-space--bottom--20">
                        <input
                          type="text"
                          placeholder="Nom"
                          name="name"
                          value={contactData.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="col-md-6 col-12 section-space--bottom--20">
                        <input
                          type="email"
                          placeholder="Email"
                          name="email"
                          value={contactData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="col-md-6 col-12 section-space--bottom--20">
                        <input
                          type="text"
                          placeholder="Objet"
                          name="objet"
                          value={contactData.objet}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="col-12">
                        <textarea
                          type="text"
                          name="message"
                          placeholder="Message"
                          maxLength="6000"
                          rows="7"
                          value={contactData.message}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="col-12">
                        <button>Envoyer</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Contact section end*/}
      </div>
      {/*====================  End of content page content  ====================*/}

      {/* Footer */}
      <Footer />

      {/* Mobile Menu */}
      <MobileMenu />
    </div>
  );
};

export default Contact;
