import React, { Component } from "react";
import NavBar from "../components/NavBar";
import SidebarUhf from "./components/Sidebar";
import UhfRfidGallery from "./components/UhfRfidGallery";
import BrandLogoSlider from "../components/BrandLogoSlider";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";

class UhfRfid extends Component {
  render() {
    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div className="breadcrumb-area breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>UHF RFID</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        <div className="page-wrapper section-space--inner--120">
          {/*Service section start*/}
          <div className="service-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-12 order-1 order-lg-2">
                  <div className="service-details">
                    {/* service gallery */}
                    <UhfRfidGallery />
                    <div className="content section-space--top--30">
                      <div className="row">
                        <div className="col-12">
                          <h2>UHF RFID Integrated Reader </h2>
                          <p>
                            Le lecteur RFID UHF RRU1861-12dbi est un lecteur
                            intégré UHF haute performance. Il est conçu sur une
                            propriété entièrement intellectuelle. Basé sur un
                            algorithme propriétaire de traitement de signal
                            numérique efficace, il prend en charge une opération
                            de lecture/écriture rapide d'étiquettes avec un taux
                            d'identification élevé. Le lecteur intégré RFID
                            RRU1861-12dbi peut être largement appliqué dans de
                            nombreux systèmes d'application RFID tels que la
                            logistique, le contrôle d'accès et le système de
                            contrôle de processus de production anti-contrefaçon
                            et industriel.
                          </p>
                        </div>
                        <div className="col-12">
                          <h2>Caractéristiques</h2>
                          <ol>Size: 445mmx4455mmx55mm</ol>
                          <ol>Weight: 2600g</ol>
                          <ol>Self-intellectual property</ol>
                          <ol>
                            Support ISO18000-6B, ISO18000-6C(EPC C1G2) protocol
                            tag
                          </ol>
                          <ol>
                            902~928MHz frequency band(frequency customization
                            optional)
                          </ol>
                          <ol>FHSS or Fix Frequency transmission</ol>
                          <ol>RF output power up to 30dbm(adjustable)</ol>
                          <ol>
                            {" "}
                            12dbi antenna optional with effect distance more
                            than 10m
                          </ol>
                          <ol>
                            Support auto-running, interactive and
                            trigger-activating work mode
                          </ol>
                          <ol>
                            Low power dissipation with single +9 DC power supply
                          </ol>
                          <ol>Support RS232, RS485, Wiegand interface</ol>
                          <ol>
                            Provide DLL and Demonstration Software Source code
                            to facilitate further development
                          </ol>
                          <p>
                            La RFID est une technologie idéale pour la
                            vérification d'identité, l'authentification des
                            produits, le contrôle d'accès, la surveillance des
                            stocks, le suivi des actifs et plus encore - ce qui
                            signifie que les lecteurs RFID sont utilisés par
                            toutes sortes d'entreprises. Les détaillants et les
                            fabricants en voient les avantages, oui, mais les
                            entreprises impliquées dans les transports en
                            commun, l'accès aux parcs et la billetterie
                            d'événements le sont aussi - des cas impliquant des
                            transactions NFC en boucle fermée et des situations
                            où des paiements sécurisés doivent être traités.
                            Contactez Oprfid.com pour découvrir quelles options
                            de lecteur RFID répondront le mieux à vos besoins
                            actuels.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-12 order-2 order-lg-1">
                  <SidebarUhf />
                </div>
              </div>
            </div>
          </div>
          {/*Service section end*/}
        </div>

        {/* Brand logo */}
        <div className="top-bar-right-wrapper">
          <a
            href={`${process.env.PUBLIC_URL}/contact-us`}
            className="ht-btn ht-btn--default d-inline-block">
            OBTENIR UN DEVIS
          </a>
        </div>
        <BrandLogoSlider background="grey-bg" />

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default UhfRfid;
